<template>
    <div class="login">
        <div class=" button btn1" @click="toLogin">
            <!-- <img src="../assets/btn@2x.png" alt=""> -->
        </div>
        <div class=" button btn2" @click="toLogin">
            <!-- <img src="../assets/immediately-btn@2x.png" alt=""> -->
        </div>
        <div class=" button btn3" @click="toLogin">
            <!-- <img src="../assets/immediately-btn@2x.png" alt=""> -->
        </div>
    </div>
  </template>
  
  <script>
  export default {
    components: {  },
    data () {
      return {
        }
    },
    created () {
    },
    methods: {
        toLogin(){
            this.$router.push('/')
        }
      
      }
  }
  </script>
  
  <style scoped>
  .login {
    min-width: 3.2rem;
    max-width: 3.75rem;
    height: 16rem;
    background: url('https://kxt-public.kuaxintong.com/kxt-cloud/2023/3/18/bg@2x294069.png') center no-repeat;
    background-size: 100% 100%;
    padding: 2.35rem 0 0;
    margin: 0 auto;
    position: relative;
  }
  .button{
    position:absolute;
    width: 2rem;
    height: 0.55rem;
    left:50%;
    margin-left:-1rem;
    background: url('https://kxt-public.kuaxintong.com/kxt-cloud/2023/3/17/immediately-btn@2x411123.png') center no-repeat;
    background-size: 100% 100%;
  }
  .btn1{ 
    width: 2.27rem;
    top:3.35rem;
    height: 0.55rem;
    margin-left:-1.14rem;
    background: url('https://kxt-public.kuaxintong.com/kxt-cloud/file/CB20230218163817417213/dc0264f9-eeca-436f-8db7-81967d83db9f820374.png') center no-repeat;
    background-size: 100% 100%;
  }
  .btn2{
    top:8.45rem;
  }
  .btn3{
    bottom:1.88rem;
  }
  img{
    width: 100%;
    height: 100%;
  }
  </style>
  